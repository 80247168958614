main {
  align-content: center;
  box-sizing: border-box;
  display: grid;
  font-family: 'SF Pro Text', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica',
    'Arial', sans-serif;
  hyphens: auto;
  margin: 0 auto;
  padding: 16px 0;
  text-align: center;
}
h1 {
  font-size: 45px;
}
h2 {
  margin-top: 1.5em;
}
p {
  font-size: 16px;
}

code,
pre {
  color: #d400ff;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 0.92em;
}
code:before,
code:after {
  content: '\`';
}

.logo {
  float: left;
  margin-left: 5vw;
}

.metric-title {
  float: right;
  margin-right: 5vw;
  margin-top: 10px;
  font-family: Roboto;
  font-size: 20px;
}

.topbar {
  margin: 12px 0;
}
